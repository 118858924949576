import React from "react";
import { useNavigate } from "react-router-dom";
import BannerImg from "../assets/products/Brown and White Aesthetic Thank You Poster Landscape_20241226_203632_0000.jpg";

const Advertisement = () => {
    const navigate = useNavigate();

    const handleImageClick = () => {
        navigate("/products"); // Navigate to the "Products" page
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-8 md:p-16">
            <div className="container mx-auto flex flex-col items-center text-center md:flex-row md:text-left md:justify-between">
                <div className="max-w-lg p-5">
                    <h1 className="text-3xl md:text-5xl font-semibold mb-4">
                        Discover the Perfect Personalized Frames
                    </h1>
                    <p className="text-lg md:text-xl mb-6">
                        You can customize your portraits just like our frames – a perfect gift to celebrate your loved ones and preserve cherished memories for a lifetime.

                    </p>
                    <button
                        className="bg-white text-blue-500 hover:bg-white px-6 py-3 rounded-lg font-medium"
                        onClick={() => {
                            navigate("/products");
                            window.scrollTo(0, 0);
                        }}
                    >
                        Explore
                    </button>
                </div>
                <div className="mt-8 md:mt-0">
                    <img
                        src={BannerImg}
                        alt="Advertisement"
                        className="rounded-lg shadow-lg max-w-full cursor-pointer"
                        onClick={handleImageClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default Advertisement;
