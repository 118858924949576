import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../constants/Navbar";
import ProfileImg from "../assets/AryanProfile.jpg"; // Replace with your image path

const AboutUs = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/Services"); // Navigate to the "About" page
    };

    return (
        <>
            {/* Navbar */}
            <Navbar className="mb-16" />

            {/* Hero Section */}
            <div className="relative flex mt-10 flex-col lg:flex-row items-center justify-center h-screen w-full px-8 lg:px-16 bg-gradient-to-br from-purple-800 via-black to-purple-900">
                {/* Background Overlay */}
                <div className="absolute inset-0 bg-gradient-to-br from-purple-800 via-black to-purple-900 opacity-80"></div>

                {/* Left Section: Text Content */}
                <div className="relative z-10 p-2 lg:w-1/2 flex flex-col text-center lg:text-left space-y-6 text-white">
                    <h1 className="text-5xl font-800 bg-clip-text text-transparent p-5 bg-gradient-to-r from-purple-300 to-purple-600">
                        Through The Lens Of Creativity
                    </h1>
                    <p className="text-sm text-gray-300 font-800 leading-relaxed">
                        At EditorAru, we don't just enhance photos and videos—we transform them into timeless masterpieces. Our mission is to empower creators by combining intuitive tools with unmatched creative flexibility. Every click, every edit, every frame, and every moment becomes a vibrant expression of your unique story.
                    </p>
                    <button
                        onClick={handleButtonClick}
                        className="bg-gradient-to-r from-purple-600 to-purple-500 text-white px-8 py-4 rounded-sm shadow-lg hover:scale-105 transition duration-300"
                    >
                        Capture Now
                    </button>
                </div>

                {/* Right Section: Image */}
                <div className="relative z-10 lg:w-1/2 flex justify-center mt-8 lg:mt-0">
                    <div className="relative w-full max-w-lg lg:max-w-xl rounded-lg shadow-2xl">
                        {/* Decorative Background Shape */}
                        <div className="absolute -inset-2 rounded-lg bg-gradient-to-br from-purple-700 to-black opacity-80 blur-2xl"></div>

                        {/* Profile Image */}
                        <div className="relative z-10 bg-gradient-to-br from-purple-800 via-black rounded-lg p-6 shadow-lg">
                            <img
                                src={ProfileImg}
                                alt="Photographer Profile"
                                className="w-full h-auto rounded-lg shadow-lg object-cover"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUs;
