import React from "react";
import Navbar from "../constants/Navbar";
import Footer from "../constants/Footer";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import ProdIMG1 from "../assets/products/Wedding Anniversary Spotify Frame.png";
import ProdIMG2 from "../assets/products/Our Love Story.png";
import ProdIMG3 from "../assets/products/Aarti Portrait.png";
import ProdIMG4 from "../assets/products/Babasaheb frame.png";
import ProdIMG5 from "../assets/products/Birthday Frame.jpg";
import ProdIMG6 from "../assets/products/BestiesForever.png";
import ProdIMG7 from "../assets/products/Digital Painting.png";

const WHATSAPP_NUMBER = "7066917801";

const ProductCard = () => {
  const products = [
    {
      id: 1,
      name: "Wedding Anniversary Spotify Frame",
      description: "Custom Photo with Spotify Code",
      price: 899,
      sizes: "12X16 (Matte Finish)",
      originalPrice: 1100,
      discount: "23% OFF",
      rating: 4.1,
      reviews: "5.2k",
      image: ProdIMG1,
    },
    {
      id: 2,
      name: "Our Love Story",
      description: "Custom Timeline Photo Frame",
      price: 599,
      sizes: "12X16 (Matte Finish)",
      originalPrice: 1200,
      discount: "50% OFF",
      rating: 4.1,
      reviews: "5.1k",
      image: ProdIMG2,
    },
    {
      id: 3,
      name: "Personalized Mosaic Portrait Frame",
      description: "Beautiful Custom Mosaic Portrait Frame",
      price: 799,
      sizes: "12X18 (Matte Finish)",
      originalPrice: 1100,
      discount: "38% OFF",
      rating: 4.5,
      reviews: "5.1k",
      image: ProdIMG3,
    },
    {
      id: 4,
      name: "Dr. Babasaheb Ambedkar Frame",
      description: "Abstract Painting Of Dr. Babasaheb Ambedkar",
      price: 499,
      sizes: "12X16 (Matte Finish)",
      originalPrice: 1550,
      discount: "60% OFF",
      rating: 4.8,
      reviews: "2.3k",
      image: ProdIMG4,
    },
    {
      id: 5,
      name: "Birthday Frame",
      description: "Creative illustration of a birthday frame.",
      price: 599,
      sizes: "12X18 (Matte Finish)",
      originalPrice: 1499,
      discount: "60% OFF",
      rating: 4.7,
      reviews: "3.7k",
      image: ProdIMG5,
    },
    {
      id: 6,
      name: "Besties Forever",
      description: "Friendship Special Frame",
      price: 599,
      sizes: "12X16 (Matte Finish)",
      originalPrice: 800,
      discount: "25% OFF",
      rating: 4.6,
      reviews: "4.1k",
      image: ProdIMG6,
    },
    {
      id: 7,
      name: "Digital Painting",
      description: "Custom Digital Artwork",
      price: 699,
      sizes: "12X16 (Matte Finish)",
      originalPrice: 1050,
      discount: "50% OFF",
      rating: 4.9,
      reviews: "5.5k",
      image: ProdIMG7,
    },
  ];

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <div className="flex text-yellow-500 text-sm">
        {[...Array(fullStars)].map((_, i) => (
          <FaStar key={`full-${i}`} />
        ))}
        {halfStar && <FaStarHalfAlt />}
        {[...Array(emptyStars)].map((_, i) => (
          <FaRegStar key={`empty-${i}`} />
        ))}
      </div>
    );
  };

  const handleOrderNow = (product) => {
    const message = `Hello, I would like to order the following product:
- Name: ${product.name}
- Description: ${product.description}
${product.sizes ? `- Size: ${product.sizes}` : ""}
- Price: Rs. ${product.price}

Please let me know the next steps!`;

    const whatsappURL = `https://wa.me/${WHATSAPP_NUMBER}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  const handleShare = (product) => {
    // Generate the URL for the current product
    const productURL = window.location.href + `#product-${product.id}`;

    // Use the image URL and product information for sharing
    const message = `Check out this product: ${product.name} - ${product.description}\n\n`;
    const imageMessage = `Image: ${product.image}\n`;
    const shareMessage = `${message}${imageMessage}See more details here: ${productURL}`;

    // Open WhatsApp with the generated message
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(shareMessage)}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <>
      <Navbar className="mb-16" />
      <div className="bg-gray-100 mt-16 py-10">
        <div className="container mx-auto px-6">
          <div className="text-center mb-8">
            <h2 className="text-3xl font-semibold text-purple-800">Our Products</h2>
            <h3 className="text-md text-gray-600 mt-2">
              Explore our collection of custom and personalized gifts, perfect for any occasion!
            </h3>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {products.map((product) => (
              <div
                key={product.id}
                className="bg-white rounded-lg shadow hover:shadow-lg overflow-hidden transform hover:-translate-y-1 transition duration-300 flex flex-col"
              >
                <div className="relative">
                  {/* Image displayed in actual size */}
                  <img
                    src={product.image}
                    alt={product.name}
                    className="max-w-full h-auto hover:scale-105 transition-transform duration-200 ease-in-out"
                  />
                </div>
                <div className="p-4 flex flex-col justify-between flex-grow">
                  <div>
                    <div className="flex items-center space-x-2 mb-1">
                      <span className="font-semibold text-sm">{product.rating}</span>
                      {renderStars(product.rating)}
                      <span className="text-xs text-gray-500">| {product.reviews}</span>
                    </div>
                    <h3 className="text-md font-semibold truncate">{product.name}</h3>
                    <p className="text-xs text-gray-600 line-clamp-2 mt-1">
                      {product.description}
                    </p>
                    {product.sizes && (
                      <p className="text-xs text-gray-600 mt-1">Size: {product.sizes}</p>
                    )}
                  </div>
                  <div className="mt-2 flex items-center">
                    <span className="text-lg font-bold text-gray-800">
                      Rs. {product.price}
                    </span>
                    <span className="text-sm text-gray-500 line-through ml-2">
                      Rs. {product.originalPrice}
                    </span>
                    <span className="text-xs text-red-600 font-semibold block ml-2">
                      ({product.discount})
                    </span>
                  </div>
                  <div className="mt-4 flex justify-between gap-2">
                    <button
                      onClick={() => handleOrderNow(product)}
                      className="w-1/2 bg-purple-700 text-white py-2 px-4 rounded font-800 hover:bg-purple-800 transition duration-200"
                    >
                      Order Now
                    </button>
                    <button
                      onClick={() => handleShare(product)}
                      className="w-1/2 bg-green-700 text-white py-2 px-4 rounded font- hover:bg-green-800 transition duration-200"
                    >
                      Share
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductCard;
